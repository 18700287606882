import {
  IFileUploadReference,
  ILogActor,
  ResponseLog,
  SubmoduleCompletionService,
} from '@dominion/interfaces';

export class CoreSubmodule {
  meta: {
    completion: any;
  };
  structure: any;
  data: any;
  completionService: SubmoduleCompletionService<string, any> | undefined;
  dataKeys: string[] = [];

  constructor() {}

  getCompletion() {
    if (this.completionService === undefined) {
      this.completionService = new SubmoduleCompletionService(
        this.data as any,
        this.structure as any,
      );
    }
    const completion = this.completionService.getCompletion();
    this.meta.completion = completion;
    return completion;
  }

  /**
   * Sets the response for a given key
   *
   * @remarks Not to be used for file uploads
   *
   * @param key - The data key to set the response for
   * @param value - The value to set
   */
  setResponse(key: string, value: any, actor: ILogActor) {
    const allowedKeys = this.getDataKeys();
    if (!allowedKeys.includes(key)) {
      throw new Error(`Key ${key} is not allowed in this submodule`);
    }
    this.data[key].value = value;
    this.addLogEvent(key, value, actor);
  }

  addFileResponse(
    key: string,
    fileRef: IFileUploadReference,
    actor: ILogActor,
  ) {
    // get the value of the key
    // if the value is not already an array, then make it one
    // add the file ref to the array
    const allowedKeys = this.getDataKeys();
    if (!allowedKeys.includes(key)) {
      throw new Error(`Key ${key} is not allowed in this submodule`);
    }
    const value = this.data[key].value;
    if (!Array.isArray(value)) {
      this.data[key].value = [];
    }
    this.data[key].value.push(fileRef);
    this.addLogEvent(key, value, actor);
    return;
  }

  removeFileResponse(key: string, fileId: string, actor: ILogActor) {
    const allowedKeys = this.getDataKeys();
    if (!allowedKeys.includes(key)) {
      throw new Error(`Key ${key} is not allowed in this submodule`);
    }
    const value = this.data[key].value;
    if (!Array.isArray(value)) {
      this.data[key].value = [];
      return;
    }
    const index = value.findIndex((file: any) => file._id === fileId);
    if (index === -1) {
      return;
    }
    value.splice(index, 1);
    this.addLogEvent(key, value, actor);
    return;
  }

  /**
   * Gets the allowed data keys from the submodule structure definition
   *
   * @returns The keys that are allowed in this submodule
   */
  getDataKeys() {
    if (this.dataKeys.length === 0) {
      let keys: string[] = [];
      Object.entries(this.structure).forEach(([groupKey, group]) => {
        groupKey;
        const questionKeys = Object.keys(
          (group as { questions: Record<string, unknown> }).questions,
        );
        keys = [...keys, ...questionKeys];
      });
      this.dataKeys = keys;
    }
    return this.dataKeys;
  }

  private addLogEvent(key: string, value: any, actor: ILogActor) {
    // if log does not exist or is not an array
    if (!this.data[key].log || !Array.isArray(this.data[key].log)) {
      this.data[key].log = [];
    }
    const event = new ResponseLog(actor, value);
    this.data[key].log.unshift(event);
  }
}

import { Model, Types } from 'mongoose';
import { VendorAuthorization } from '../company/vendor-authorization.interfaces';

export type TVendorStatus = 'active' | 'inactive' | 'pending';

export interface IVendor {
  _id: string;
  vendorType: 'standard';
  providerName: string;
  appName: string;
  status: TVendorStatus;
  desc: string;
}

export interface IVendorCustom {
  _id: string;
  vendorType: 'custom';
  providerName: IVendor['providerName'];
  appName: IVendor['appName'];
  status: null;
  keys: string[];
  streams: null;
  desc: null;
  authorizations: string[];
}

export interface IStandardVendorCompanyRef {
  vendorId: string;
  keys: string[];
  authorizations: string[];
}

export interface IVendorAuthRef {
  _id: IVendor['_id'];
  vendorType: IVendor['vendorType'];
  providerName: IVendor['providerName'];
  appName: IVendor['appName'];
}

export interface IVendorInitializer {
  providerName: IVendor['providerName'];
  appName: IVendor['appName'];
  desc?: IVendor['desc'];
  status?: TVendorStatus;
}

export interface IVendorCustomInitializer {
  providerName: IVendorCustom['providerName'];
  appName?: IVendorCustom['appName'];
}

export interface IVendorModelOverrides {
  _id: Types.ObjectId;
  streams: Types.Array<Types.ObjectId>;
}

export interface IVendorModel
  extends Model<IVendor, {}, IVendorModelOverrides> {}

// CREATE

export interface ICreateVendorDto {
  providerName: IVendor['providerName'];
  appName: IVendor['appName'];
  desc?: IVendor['desc'];
  status?: TVendorStatus;
}

export interface IVendorCustomCreateDto {
  companyId: string;
  providerName: IVendorCustom['providerName'];
  appName?: IVendorCustom['appName'];
}

// READ

export interface IGetVendorsByDto {
  vendorIds: string[];
}

export interface IGetVendorsResponse {
  count: number;
  vendors: IVendor[];
}

export interface IGetVendorsByIdDto {
  vendorIds: string[];
}

export interface IGetCompanyVendorsDto {
  companyId: string;
}

// UPDATE

export interface IVendorUpdateDto {
  vendorId: string;
  providerName?: IVendor['providerName'];
  appName?: IVendor['appName'];
  desc?: IVendor['desc'];
  status?: TVendorStatus;
}

export interface IAssignVendorToCompanyDto {
  vendorType: IVendor['vendorType'] | IVendorCustom['vendorType'] | 'none';
  companyId: string;
  moduleId: string;
  submoduleId: string;
  questionKey: string;
  vendorId?: string | null;
}

export interface IVendorUpdateEvent {
  field: Exclude<keyof IVendorUpdateDto, 'vendorId'>;
  value: IVendorUpdateDto[Exclude<keyof IVendorUpdateDto, 'vendorId'>];
}

export interface IVendorDiscoveryDataResponse {
  vendorType: 'standard' | 'custom' | 'none';
  vendorId: string | null;
}

export interface ICompanyVendors {
  vendors: IVendor[];
  customVendors: IVendorCustom[];
  vendorAuthorizationSnapshots: VendorAuthorization[];
}
